import React from "react";

const BottomInfoSection = () => {
  const cigarBrands = [
    "img/brand-1.jpg",
    "img/brand-2.jpg",
    "img/brand-3.jpg",
    "img/brand-4.jpg",
    "img/brand-5.jpg",
    "img/brand-6.jpg",
    "img/brand-7.jpg",
    "img/brand-8.jpg",
    "img/brand-9.jpg",
    "img/brand-10.jpg",
    "img/brand-11.jpg",
    "img/brand-12.jpg",
    "img/brand-13.jpg",
    "img/brand-14.jpg",
    "img/brand-15.jpg",
    "img/brand-16.jpg",
  ];

  return (
    <div
      className="info-section"
      style={{
        paddingBottom: "1rem", // Relative padding
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          flexWrap: "wrap", // Allows wrapping for smaller screens
        }}
      >
        <img
          src={"img/garturbo-brand.jpeg"}
          alt="Logo"
          style={{
            width: "40%", // Responsive width
            maxWidth: "200px", // Set a maximum limit for larger screens
            height: "auto", // Maintain aspect ratio
            borderRadius: "1rem", // Rounded corners using relative units
            marginBottom: "1rem", // Add spacing for small screens
            marginRight: "2rem",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Stack items vertically
            alignItems: "center", // Center aligns text and QR code horizontally
          }}
        >
          <img
            src={"img/QR-code.jpg"}
            alt="WeChat QR Code"
            style={{
              width: "8rem", // Relative size for QR code
              marginBottom: "0.1rem", // Space between QR code and text
            }}
          />
          <span
            style={{
              fontSize: "0.6rem", // Font size relative to the root
              color: "#333",
              fontWeight: "bold",
              textAlign: "center", // Center aligns text below the QR code
            }}
          >
            微信客服
          </span>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f9f9f9", // Slightly darker than white for distinction
          borderRadius: "0.5rem", // Rounded corners for a polished look
          padding: "1rem", // Add padding inside the section
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for elevation
        }}
      >
      <ul
        style={{
          marginTop: "1rem",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          marginLeft: "1rem",
          fontWeight: "bolder",
          lineHeight: "1.8", // Refined line spacing for readability
          fontSize: "0.7rem", // Modern, clean font size
          color: "#333", // Neutral text color for a modern look
        }}
      >
        <li style={{ marginBottom: "0.5rem" }}>
          本公司產品均為正品古巴雪茄, 請您放心購買
        </li>
        <li style={{ marginBottom: "0.5rem" }}>
          本站僅提供選購產品功能，選購完成後請添加微信客服，即有專人與您對接付款及交收事宜
        </li>
        <li>本站所有產品均為港幣最終價格</li>
      </ul>
      </div>

      {/* New Cigar Brands Section */}
      <div
        style={{
          marginTop: "2rem",
          marginLeft: "2rem",
          marginRight: "2rem",
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Wrap items for responsive layout
            justifyContent: "center",
            gap: "0.5rem", // Add spacing between items
          }}
        >
          {cigarBrands.map((brand, index) => (
            <img
              key={index}
              src={brand}
              alt={`Cigar Brand ${index + 1}`}
              style={{
                width: "10%", // Image size
                height: "auto", // Maintain aspect ratio
                borderRadius: "0.5rem",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = "scale(1.1)";
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = "scale(1)";
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomInfoSection;
