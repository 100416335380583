import React, { useState } from "react";
import Select from "react-select";
import imageCompression from "browser-image-compression";

const categoryOptions = [
  { value: "整盒購買", label: "入門口糧" },
  { value: "限時優惠", label: "限時优惠" },
  { value: "陳年老茄", label: "陳年高端" },
];

const AddProductForm = ({ onSave, onCancel }) => {
  const [newProduct, setNewProduct] = useState({
    title: "",
    englishTitle: "",
    brand: "",
    year: "",
    category: "整盒購買", // Default value
    img: "",
    price: "",
    stock: "",
  });

  const [previewImage, setPreviewImage] = useState("");
  const [detailImages, setDetailImages] = useState([]);
  const [detailImagePreviews, setDetailImagePreviews] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleCategoryChange = (selectedOption) => {
    setNewProduct({ ...newProduct, category: selectedOption.value });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    try {
      // Options for main image compression
      const mainImageOptions = {
        maxSizeMB: 0.3, // Limit to 100 KB
        maxWidthOrHeight: 300, // Resize dimensions
        useWebWorker: true,
        fileType: "image/webp",
      };
  
      // Compress the main image
      const mainCompressedBlob = await imageCompression(file, mainImageOptions);
  
      const originalNameWithoutExt = file.name.split(".").slice(0, -1).join(".");
      const mainUniqueFileName = `${Date.now()}_${originalNameWithoutExt}_main.webp`;
  
      const mainCompressedFile = new File([mainCompressedBlob], mainUniqueFileName, {
        type: "image/webp",
      });
  
      // Preview the compressed main image
      const mainReader = new FileReader();
      mainReader.onloadend = () => {
        setPreviewImage(mainReader.result);
      };
      mainReader.readAsDataURL(mainCompressedFile);
  
      // Update the `newProduct` state with the compressed main image
      setNewProduct({
        ...newProduct,
        img: mainUniqueFileName,
        image: mainCompressedFile,
      });
  
      // Options for detail image compression
      const detailImageOptions = {
        maxSizeMB: 1.5, // Limit to 200 KB
        maxWidthOrHeight: 700, // Resize dimensions
        useWebWorker: true,
        fileType: "image/webp",
      };
  
      // Compress the image for the detail list
      const detailCompressedBlob = await imageCompression(file, detailImageOptions);
  
      const detailUniqueFileName = `${Date.now()}_${originalNameWithoutExt}_detail.webp`;
  
      const detailCompressedFile = new File([detailCompressedBlob], detailUniqueFileName, {
        type: "image/webp",
      });
  
      // Add the compressed detail image to the list
      setDetailImages((prevDetailImages) => [...prevDetailImages, detailCompressedFile]);
  
      // Generate and add a preview for the detail image
      const detailReader = new FileReader();
      detailReader.onloadend = () => {
        setDetailImagePreviews((prevPreviews) => [...prevPreviews, detailReader.result]);
      };
      detailReader.readAsDataURL(detailCompressedFile);
    } catch (error) {
      console.error("Image compression failed:", error);
    }
  };
  
  
  

  const handleDetailImagesUpload = async (e) => {
    const files = Array.from(e.target.files);
  
    const previews = [];
    const newCompressedFiles = [];
  
    for (const file of files) {
      try {
        // Compression options
        const options = {
          maxSizeMB: 1.5, // Limit to 500 KB
          maxWidthOrHeight: 700, // Resize image dimensions
          useWebWorker: true,
          fileType: "image/webp",
        };
  
        const compressedBlob = await imageCompression(file, options);
  
        const originalNameWithoutExt = file.name.split(".").slice(0, -1).join(".");
        const uniqueFileName = `${Date.now()}_${originalNameWithoutExt}.webp`;
  
        const compressedFile = new File([compressedBlob], uniqueFileName, {
          type: "image/webp",
        });
  
        // Add compressed file to new files array
        newCompressedFiles.push(compressedFile);
  
        // Generate preview for compressed file
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result);
          setDetailImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Detail image compression failed:", error);
      }
    }
  
    // Append the new compressed files to `detailImages` without overwriting
    setDetailImages((prevDetailImages) => [...prevDetailImages, ...newCompressedFiles]);
  };
  

  return (
    <div style={{ padding: "20px", border: "1px solid #ddd", borderRadius: "5px" }}>
      <h3 style={{ textAlign: "center" }}>添加新产品</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave({ ...newProduct, detailImages });
        }}
      >
        {["title", "englishTitle", "brand", "year", "price", "stock"].map((field) => (
          <div key={field} style={{ marginBottom: "15px" }}>
            <label>
              {field === "title"
                ? "名称"
                : field === "englishTitle"
                ? "英文名"
                : field === "year"
                ? "年份"
                : field === "price"
                ? "价格"
                : field === "brand"
                ? "品牌"
                : "库存"}
              :
            </label>
            <input
              type={field === "price" || field === "stock" ? "number" : "text"}
              name={field}
              value={newProduct[field]}
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>
        ))}

        {/* Category Dropdown */}
        <div style={{ marginBottom: "15px" }}>
          <label>类别:</label>
          <Select
            options={categoryOptions}
            isSearchable={false}
            value={categoryOptions.find((option) => option.value === newProduct.category)}
            onChange={handleCategoryChange}
            styles={{
              control: (base) => ({
                ...base,
                borderColor: "#ccc",
                borderRadius: "4px",
                padding: "4px",
              }),
            }}
          />
        </div>

        {/* Main Image Upload */}
        <div style={{ marginBottom: "15px" }}>
          <label>图片:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "block", marginBottom: "10px" }}
          />
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          )}
        </div>

        {/* Detail Images Upload */}
        <div style={{ marginBottom: "15px" }}>
          <label>详细图片:</label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleDetailImagesUpload}
            style={{ display: "block", marginBottom: "10px" }}
          />
          {detailImagePreviews.length > 0 && (
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {detailImagePreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`Detail Preview ${index + 1}`}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
              ))}
            </div>
          )}
        </div>

        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            style={{
              marginRight: "10px",
              padding: "5px 10px",
              fontSize: "14px",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            保存
          </button>
          <button
            type="button"
            onClick={onCancel}
            style={{
              padding: "5px 10px",
              fontSize: "14px",
              backgroundColor: "#dc3545",
              color: "white",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            取消
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProductForm;
