import React, { useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ProductConsumer } from "../context";
import { ThemeConsumer } from "./context/ThemeContexts";

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? "#b68e5b" : "black")};
  margin: 0 3px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: "#b68e5b";
  }
`;

export default function Product({ product }) {
  const { id, title, englishTitle, year, img, price, detailImages } = product;
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current image index
  const imageWrapperRef = useRef(null);

  const handleImageClick = () => {
    setIsCarouselOpen(true);
  };

  const handleCloseCarousel = () => {
    setIsCarouselOpen(false);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    if (imageWrapperRef.current) {
      imageWrapperRef.current.scrollTo({
        left: index * imageWrapperRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (imageWrapperRef.current) {
      const index = Math.floor(imageWrapperRef.current.scrollLeft / imageWrapperRef.current.offsetWidth);
      setCurrentIndex(index);
    }
  };

  return (
    <ThemeConsumer>
      {({ theme }) => (
        <ProductWrapper
          className={`product-item ${theme ? "dark-theme" : ""}`}
          style={{ width: "100%" }}
        >
          <div className="product-container">
            {/* Left Section: Image */}
            <div className="product-image" onClick={handleImageClick}>
              <img
                src={img}
                alt={title}
                loading="lazy"
                style={{
                  filter: "blur(5px)",
                  transition: "filter 0.3s ease-out",
                }}
                onLoad={(e) => (e.target.style.filter = "none")}
              />
            </div>

            {/* Right Section: Details */}
            <div className="product-details">
              <h3 className="product-title">{title}</h3>
              <p className="product-english-name">{englishTitle}</p>
              <p className="product-year">{year}</p>
              <div className="product-price-row">
                <span className="product-price">HKD ${price}</span>
                <ProductConsumer>
                  {(value) => {
                    const productInCart = value.cart.find(
                      (item) => item.id === id
                    );
                    const quantity = productInCart ? productInCart.count : 0;
                    const { stock } = product;

                    return (
                      <div className="quantity-controls">
                        <button
                          className="control-button decrement"
                          onClick={() => value.decrement(id)}
                          disabled={quantity === 0}
                        >
                          −
                        </button>
                        <span className="quantity">{quantity}</span>
                        <button
                          className="control-button increment"
                          onClick={() => value.increment(id)}
                          disabled={quantity >= stock}
                        >
                          +
                        </button>
                      </div>
                    );
                  }}
                </ProductConsumer>
              </div>
            </div>
          </div>

          {isCarouselOpen && (
            <div className="carousel-modal">
              <div className="carousel-overlay" onClick={handleCloseCarousel} />
              <div className="carousel-content">
                <button className="close-button" onClick={handleCloseCarousel}>
                  ×
                </button>
                <div className="carousel-images">
                  <div className="carousel-scroll" ref={imageWrapperRef} onScroll={handleScroll}>
                    {detailImages && detailImages.length > 0
                      ? detailImages?.map((image, index) => (
                          <img
                            key={index}
                            src={`https://api.garturbo.com/images/${image}`} // Ensure the URL matches your backend
                            alt={`Detail Image ${index + 1}`}
                            className="carousel-image"
                          />
                        ))
                      : null}
         
                  </div>
                </div>
                <DotContainer>
                      {detailImages?.map((_, index) => (
                        <Dot
                          key={index}
                          isActive={index === currentIndex}
                          onClick={() => handleDotClick(index)}
                        />
                      ))}
                    </DotContainer>
              </div>
            </div>
          )}
        </ProductWrapper>
      )}
    </ThemeConsumer>
  );
}

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    englishTitle: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

const ProductWrapper = styled.div`
  .product-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    max-width: 91%; /* Constrain the width of the product card */
    margin-left: 0.3rem;
    transition: box-shadow 0.3s ease-in-out;
  }

  .product-container:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .product-image {
    flex: 0 0 6rem; /* Set fixed width for the image container */
    margin-right: 0.5rem;
  }

  .product-image img {
    width: 100%; /* Stretch to container width */
    height: 6rem; /* Set consistent height */
    border-radius: 5%; /* Rounded corners */
    object-fit: crop; /* Crop image to maintain aspect ratio */
  }

  .product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .product-title {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0 0 0.2rem;
    color: #333;
  }

  .product-english-name {
    font-size: 0.6rem;
    color: #555;
    margin: 0 0 0.1rem;
  }

  .product-year {
    font-size: 0.6rem;
    color: #777;
    margin: 0 0 0.2rem;
  }

  .product-price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-price {
    font-size: 0.6rem;
    font-weight: bold;
    color: #6b6b6b;
  }

  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 0.25rem; /* Adjust gap for smaller buttons */
  }

  .control-button {
    width: 20px; /* Smaller size */
    height: 20px;
    border-radius: 50%;
    font-size: 1rem; /* Smaller font size */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    &.decrement {
      background-color: transparent;
      border: 1px solid #b68e5b; /* Thinner border */
      color: #b68e5b;
    }

    &.increment {
      background-color: #b68e5b;
      border: none;
      padding-bottom: 2px;
      color: #fff;
    }
  }

  .control-button.increment:disabled {
    background-color: #e0e0e0; /* Gray background for disabled state */
    color: #a0a0a0; /* Light gray text color */
    cursor: not-allowed; /* Show a not-allowed cursor */
    border: 1px solid #d0d0d0; /* Light gray border */
  }

  .quantity {
    font-size: 1rem; /* Size of the quantity number */
    font-weight: bold;
    color: #333;
    min-width: 20px; /* Ensure consistent spacing */
    text-align: center;
  }
  .carousel-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .carousel-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px; /* Button width */
    height: 25px; /* Button height */
    font-size: 12px;
    background: #fff; /* Background color */
    border: 2px solid #ccc; /* Border color */
    border-radius: 50%; /* Makes it a circle */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional shadow */
    transition: background-color 0.2s ease, transform 0.2s ease; /* Smooth transition */
  }

  .carousel-images img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .carousel-scroll {
    display: flex;
    gap: 10px; /* Space between images */
    overflow-x: auto;
    scroll-behavior: smooth; /* Smooth scrolling */
    max-width: 100%; /* Prevent overflowing */
  }

  .carousel-scroll::-webkit-scrollbar {
    height: 8px; /* Scrollbar height */
  }

  .carousel-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  .carousel-scroll::-webkit-scrollbar-track {
    background: transparent;
  }

  .carousel-image {
    flex: 0 0 auto; /* Prevent shrinking and enable horizontal scrolling */
    max-width: 300px; /* Adjust size as needed */
    max-height: 300px; /* Adjust size as needed */
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;
